<template>
	<div>
		<div class="form-group">
			<AskIntraLocation :default_location_id="default_location_id" :selected_location.sync="intra_location" :residence_id="residence_id" />
	    </div>

	    <div class="form-group">
			<label>{{ $t("mouvement.date_mouvement") }}<sup>*</sup></label>
            <e-datepicker id="date_ouverture" v-model="date" />
		</div>

		<div class="form-group">
            <label class="col-form-label">{{ $t('acte.choix_contact') }}</label>
			<div class="row">
				<div class="col">
					<e-select
						v-model="contact"
						id="contact_id"
						label="contact_label"
						track-by="contact_id"
						:placeholder="$t('acte.selectionnez_contact')"
						:selectedLabel="$t('global.selected_label')"
						:options="contacts"
						:allow-empty="true"
						:loading="isLoadingContact"
						:show-labels="false"
						:group-select="false"
						@input="setContact"
					>
						<template slot="option" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
				<div class="col-auto">
					<button class="btn btn-secondary" @click="addContact"><font-awesome-icon :icon="['far', 'plus']" /></button>
				</div>
			</div>
        </div>
	</div>
</template>

<script type="text/javascript">
	import Lieux from '@/mixins/Lieux'
	import Contact from "@/mixins/Contact.js"

	export default {
		name: 'WriteInterne',
		props: ['destination_lieu', 'mouvement_date', 'selected_contact', 'residence_id', 'default_location_id', 'selected_location'],
		mixins: [Lieux, Contact],
		data () {
			return {
				date: new Date(),
	            contact: null,
	            contacts: [],
	            isLoadingContact: false,
	            intra_location: {},
	            all_lieux: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.date = this.mouvement_date
				this.contact = this.selected_contact
				this.intra_location = this.selected_location

	            this.isLoadingContact = true
	            this.contacts = await this.getAllContact(true)
	            this.isLoadingContact = false

	            this.all_lieux = await this.getLieux(false)
			},

			setContact() {
				return this.$emit('update:selected_contact', this.contact)
			},

			setLocalation(val) {
				if(this.all_lieux.length === 0) {
					return false
				}

				let residence = null
				if(this.residence_id) {
					residence = this.all_lieux.find(lieu => lieu.lieu_id == this.residence_id)
				}
				else {
	           		residence = this.all_lieux.find(lieu => lieu.lieu_id == val.root_lieu.lieu_id)
				}

	            val.mouvement_adresse = residence.lieu_adresse
				val.mouvement_cp = residence.lieu_cp
				val.mouvement_ville = residence.lieu_ville
				return this.$emit('update:selected_location', val)
			}
		},

	    computed: {
	        hasFacturationAccess() {
	            return this.$store.state.userAccess.hasFacturationAccess
	        },
	    },

		watch: {
			'date' (val) {
				return this.$emit('update:mouvement_date', val)
			},
			'intra_location' (val) {
				if(val) {
					this.setLocalation(val)
				}
			},
		},

		components: {
			AskIntraLocation: () => import('@/components/Mouvements/AskIntraLocation'),
		}
	}

</script>